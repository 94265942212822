import { createGlobalStyle } from 'styled-components';
import theme from './theme';
/**
 * This component lets you use spacing more transparent and flexible,
 * by adding [[Space Class]] to block.
 *
 * Example HTML:
 *
 * <div class="space-m-t-10 sm-space-m-t-3_5"></div>
 * [?sm]-space-[m,p]-[t,b,l,r]-[0-32]
 *
 * [?sm] - prefix that applies styles for mobile
 * space - class name
 * [m,p] - m - using maring, p - using padding
 * [t,b,l,r] - prefix for direction can be: top, bottom, left, right
 * [0-32] - spacing unit multiplier with step 0.5
 */

const SpaceGenerator = () => {
  let spacesStyles = '';
  let mobileSpacesStyles = `${theme.breakpoints.down('xs')} {`;
  const prefix = '.space';
  const dirs = ['l', 't', 'r', 'b'];
  const types = ['p', 'm'];
  const dirMap = {
    l: 'left',
    r: 'right',
    b: 'bottom',
    t: 'top',
  };

  const typeMap = {
    p: 'padding',
    m: 'margin',
  };

  const maxSpacingUnit = 32;
  const step = 0.5;
  const vwMultiplier = 0.4166667;

  const sizes = Array.from(Array(maxSpacingUnit * 2 + 1), (x, index) => 0 + index * step);

  types.forEach(type => {
    dirs.forEach(dir => {
      sizes.forEach(space => {
        spacesStyles += `${prefix}-${type}-${dir}-${space.toString().replace('.', '_')} {
          ${typeMap[type]}-${dirMap[dir]}: ${theme.spacing(space)}px !important;
          ${typeMap[type]}-${dirMap[dir]}: ${space * vwMultiplier}vw !important;
        }`;

        mobileSpacesStyles += `.sm-${prefix.substr(1)}-${type}-${dir}-${space
          .toString()
          .replace('.', '_')} {
          ${typeMap[type]}-${dirMap[dir]}: ${theme.spacing(space)}px !important;
          ${typeMap[type]}-${dirMap[dir]}: ${space * vwMultiplier}vw !important;
        }`;
      });
    });
  });

  mobileSpacesStyles += '}';
  spacesStyles += mobileSpacesStyles;

  return spacesStyles;
};

const GlobalSpaceStyles = createGlobalStyle`
  ${SpaceGenerator};
`;

export default GlobalSpaceStyles;
