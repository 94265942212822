import { createGlobalStyle } from 'styled-components';
import { withColorMode } from '@context/ColorModeContext';

const GlobalTypographyStyles = createGlobalStyle`
  body {
    position: relative;
    transition: background-color 0.2s linear;
    background-color: ${({ background }) => background.color || 'transparent'};
    color: ${({ theme }) => theme.colors.dark};

    #___gatsby {
      background-color: inherit;
    }
  }

  * {
    font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
  }

  ${({ theme }) =>
    ['h1', 'h2', 'h3', 'h4'].map(
      tag => `
    ${tag} {
      margin: 0;
      font-size: ${theme.typography[tag].fontSize};
      font-weight: ${theme.typography[tag].fontWeight};
      line-height: ${theme.typography[tag].lineHeight};

      ${theme.breakpoints.down('sm')} {
        font-size: ${theme.typography[tag][theme.breakpoints.down('sm')].fontSize};
        line-height: ${theme.typography[tag][theme.breakpoints.down('sm')].lineHeight};
      }
    }
  `
    )}
  
  a {
    color: ${({ theme }) => theme.colors.dark};
  }

  p {
    margin: 0;
    font-size: ${({ theme }) => theme.typography.body2.fontSize};
    font-weight: ${({ theme }) => theme.typography.body2.fontWeight};
    line-height: ${({ theme }) => theme.typography.body2.lineHeight};

    ${({ theme }) => theme.breakpoints.down('sm')} {
      font-size: ${({ theme }) => theme.typography.body2[theme.breakpoints.down('sm')].fontSize};
      line-height: ${({ theme }) =>
        theme.typography.body2[theme.breakpoints.down('sm')].lineHeight};
    }
  }

  p + p {
    margin-top: ${({ theme }) => theme.spacing(4)}px;

    ${({ theme }) => theme.breakpoints.down('sm')} {
      margin-top: ${({ theme }) => theme.spacing(2.5)}px;
    }
  }

  h1 + p, h2 + p {
    margin-top: ${({ theme }) => theme.spacing(4)}px;

    ${({ theme }) => theme.breakpoints.down('sm')} {
      margin-top: ${({ theme }) => theme.spacing(2)}px;
    }
  }

  h1 + p {
    font-size: ${({ theme }) => theme.typography.body1.fontSize};
    font-weight: ${({ theme }) => theme.typography.body1.fontWeight};
    line-height: ${({ theme }) => theme.typography.body1.lineHeight};

    ${({ theme }) => theme.breakpoints.down('sm')} {
      font-size: ${({ theme }) => theme.typography.body1[theme.breakpoints.down('sm')].fontSize};
      line-height: ${({ theme }) =>
        theme.typography.body1[theme.breakpoints.down('sm')].lineHeight};
    }
  }

  h3 + p,
  h4 + p {
    margin-top: ${({ theme }) => theme.spacing(1.5)}px;
  }

  pre {
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -pre-wrap;
    white-space: -o-pre-wrap;
    word-wrap: break-word;
  }
`;

export default withColorMode(GlobalTypographyStyles);
