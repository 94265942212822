/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import React from 'react';
import Root from '@c/Root';

export const wrapPageElement = props => {
  const {
    props: { pageContext },
  } = props;

  if (pageContext.emailHTML) {
    document.open();
    document.write(pageContext.emailHTML);
    document.close();

    return;
  }

  return <Root {...props} />;
};
