import React from 'react';
import ColorModeContext from './ColorModeContext';

const withColorMode = Component => props => (
  <ColorModeContext.Consumer>
    {([colorMode]) => <Component {...colorMode} {...props} />}
  </ColorModeContext.Consumer>
);

export default withColorMode;
