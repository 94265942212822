import { SET_COLOR_MODE } from './actions';

const initialBackgroundState = {
  color: 'transparent',
};
const initialHeaderState = {
  mode: 'light',
};
const initialVisibilityState = {
  hiddenContentVisible: true,
  visibleContentId: null,
};

export const initialState = {
  background: initialBackgroundState,
  header: initialHeaderState,
  visibility: initialVisibilityState,
};

export default ({ background, header, visibility }, action) => ({
  background: backgroundReducer(background, action),
  header: headerReducer(header, action),
  visibility: visibilityReducer(visibility, action),
});

const backgroundReducer = (state, action) => {
  switch (action.type) {
    case SET_COLOR_MODE:
      return action.background || state;
    default:
      return state;
  }
};

const headerReducer = (state, action) => {
  switch (action.type) {
    case SET_COLOR_MODE:
      return action.header || state;
    default:
      return state;
  }
};

const visibilityReducer = (state, action) => {
  switch (action.type) {
    case SET_COLOR_MODE:
      return {
        ...state,
        hiddenContentVisible: true,
        ...action.visibility,
      };
    default:
      return state;
  }
};
