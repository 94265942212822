import React from 'react';
import GlobalTypographyStyles from './typography';
import GlobalSpacesStyles from './spaces';

const GlobalStyles = () => (
  <>
    <GlobalTypographyStyles />
    <GlobalSpacesStyles />
  </>
);

export default GlobalStyles;
