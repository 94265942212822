import { createMuiTheme } from '@material-ui/core/styles';
import { transparentize } from 'polished';
import colors from './colors';

const initialTheme = createMuiTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 768,
      md: 1024,
      lg: 1440,
      xl: 2200,
    },
  },
});

const theme = createMuiTheme(
  {
    colors,
    header: {
      height: 144,
      [initialTheme.breakpoints.down('sm')]: {
        height: 80,
      },
    },
    typography: {
      fontFamily: 'EuclidCircular, serif',
      htmlFontSize: 16,
      h1: {
        fontSize: '3.375rem',
        fontWeight: 500,
        lineHeight: 1.22,
        [initialTheme.breakpoints.down('sm')]: {
          fontSize: '1.6875rem',
          lineHeight: 1.3,
        },
      },
      h2: {
        fontSize: '2.625rem',
        fontWeight: 500,
        lineHeight: 1.33,
        [initialTheme.breakpoints.down('sm')]: {
          fontSize: '1.625rem',
          lineHeight: 1.31,
        },
      },
      h3: {
        fontSize: '2rem',
        fontWeight: 500,
        lineHeight: 1.25,
        [initialTheme.breakpoints.down('sm')]: {
          fontSize: '1.375rem',
          lineHeight: 1.27,
        },
      },
      h4: {
        fontSize: '1.625rem',
        fontWeight: 500,
        lineHeight: 1.31,
        [initialTheme.breakpoints.down('sm')]: {
          fontSize: '1.375rem',
          lineHeight: 1.27,
        },
      },
      body1: {
        fontSize: '1.5rem',
        fontWeight: 'normal',
        lineHeight: 1.67,
        [initialTheme.breakpoints.down('sm')]: {
          fontSize: '1.0625rem',
          lineHeight: 1.65,
        },
      },
      body2: {
        fontSize: '1.25rem',
        fontWeight: 'normal',
        lineHeight: 1.6,
        [initialTheme.breakpoints.down('sm')]: {
          fontSize: '1.0625rem',
          lineHeight: 1.65,
        },
      },
    },
    overrides: {
      MuiContainer: {
        root: {
          paddingLeft: '10%',
          paddingRight: '10%',
          [initialTheme.breakpoints.up('sm')]: {
            paddingLeft: '10%',
            paddingRight: '10%',
          },
        },
      },
      MuiFormLabel: {
        root: {
          '&$focused': {
            color: colors.dark,
          },
        },
      },
      MuiInputLabel: {
        root: {
          fontSize: '1.25rem',
          lineHeight: 1.2,
          color: transparentize(0.32, colors.dark),
          [initialTheme.breakpoints.down('sm')]: {
            fontSize: '1rem',
            lineHeight: 1.5,
          },
        },
        shrink: {
          fontSize: '1rem',
          fontWeight: 600,
          lineHeight: 1.33,
          color: colors.dark,
          letterSpacing: '0.5px',
          textTransform: 'uppercase',
          left: 0,
          [initialTheme.breakpoints.down('sm')]: {
            fontSize: '0.85rem',
            lineHeight: 1.2,
            letterSpacing: '0.4px',
          },
        },
        formControl: {
          left: initialTheme.spacing(3),
          transform: `translate(0, ${initialTheme.spacing(6)}px) scale(1)`,
          [initialTheme.breakpoints.down('sm')]: {
            left: initialTheme.spacing(2),
            transform: `translate(0, ${initialTheme.spacing(4.75)}px) scale(1)`,
          },
        },
        asterisk: {
          display: 'none',
        },
      },
      MuiInput: {
        root: {
          fontSize: '1.25rem',
          lineHeight: 1.2,
          border: `1px solid ${transparentize(0.76, colors.dark)}`,
          backgroundColor: transparentize(0.98, colors.dark),
          padding: `${initialTheme.spacing(2)}px ${initialTheme.spacing(3)}px`,
          '&$focused': {
            borderColor: colors.dark,
            boxShadow: `0 0 0 1px ${colors.dark}`,
          },
          '&$error': {
            borderColor: initialTheme.palette.error.main,
            boxShadow: `0 0 0 1px ${initialTheme.palette.error.main}`,
          },
          [initialTheme.breakpoints.down('sm')]: {
            fontSize: '1rem',
            lineHeight: 1.5,
            padding: `${initialTheme.spacing(1.5)}px ${initialTheme.spacing(2)}px`,
          },
        },
        input: {
          padding: 0,
          '&:focus': {
            color: colors.dark,
          },
        },
        formControl: {
          'label + &': {
            marginTop: initialTheme.spacing(4),
            [initialTheme.breakpoints.down('sm')]: {
              marginTop: initialTheme.spacing(3.5),
            },
          },
        },
      },
      MuiSelect: {
        root: {
          position: 'relative',
          fontSize: '1.25rem',
          lineHeight: 1.2,
          backgroundColor: transparentize(0.98, colors.dark),
          padding: `${initialTheme.spacing(2)}px ${initialTheme.spacing(3)}px`,
        },
      },
      MuiFormHelperText: {
        root: {
          position: 'absolute',
          bottom: `-${initialTheme.spacing(3)}px`,
        },
      },
      MuiButton: {
        root: {
          fontSize: '0.75rem',
          fontWeight: 600,
          lineHeight: 1.33,
          letterSpacing: '0.5px',
          borderRadius: 0,
        },
        text: {
          color: transparentize(0.32, colors.dark),
          padding: initialTheme.spacing(3),
          '&:hover': {
            color: colors.dark,
            backgroundColor: 'transparent',
          },
          '&:focus': {
            color: colors.dark,
            backgroundColor: 'transparent',
          },
        },
        outlined: {
          padding: initialTheme.spacing(3),
          border: `1px solid ${colors.dark}`,
          '& $endIcon': {
            transition: 'margin-left .2s ease-in-out',
            marginLeft: initialTheme.spacing(1.5),
          },
          '&:hover': {
            backgroundColor: 'transparent',
            boxShadow: `0 0 0 1px ${colors.dark}`,
            '& $endIcon': {
              marginLeft: initialTheme.spacing(3),
            },
          },
        },
        contained: {
          color: colors.white,
          backgroundColor: colors.blue,
          border: `1px solid ${colors.dark}`,
          padding: initialTheme.spacing(3),
          boxShadow: 'none',
          '&:hover': {
            backgroundColor: `${colors.blue}!important`,
            boxShadow: 'none!important',
          },
          '&:focus': {
            backgroundColor: colors.dark,
          },
          '&:active': {
            boxShadow: 'none',
          },
        },
        sizeSmall: {
          fontSize: '0.75rem',
          padding: `${initialTheme.spacing(2)}px ${initialTheme.spacing(3)}px`,
        },
      },
      MuiIconButton: {
        root: {
          '&:hover': {
            backgroundColor: `transparent`,
          },
          '&$disabled': {
            opacity: 0.12,
          },
        },
      },
      MuiAlert: {
        icon: {
          alignItems: 'center',
        },
        message: {
          '& a': {
            color: 'inherit',
          },
        },
      },
      MuiDialog: {
        paper: {
          background: colors.bg,
        },
      },
      MuiDialogTitle: {
        root: {
          padding: initialTheme.spacing(3),
        },
      },
      MuiDialogContent: {
        root: {
          paddingTop: 0,
          paddingRight: initialTheme.spacing(16),
          paddingBottom: initialTheme.spacing(12),
          paddingLeft: initialTheme.spacing(16),
          [initialTheme.breakpoints.down('sm')]: {
            paddingRight: initialTheme.spacing(3.5),
            paddingBottom: initialTheme.spacing(3.5),
            paddingLeft: initialTheme.spacing(3.5),
          },
        },
      },
    },
    props: {
      MuiContainer: {
        maxWidth: 'xl',
      },
      MuiInput: {
        disableUnderline: true,
      },
      MuiButton: {
        disableRipple: true,
      },
      MuiIconButton: {
        disableRipple: true,
      },
      MuiDialog: {
        PaperProps: {
          square: true,
        },
      },
      MuiDialogTitle: {
        disableTypography: true,
      },
    },
  },
  {
    breakpoints: initialTheme.breakpoints,
  }
);

export default theme;
