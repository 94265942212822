import React from 'react';
import PropTypes from 'prop-types';
import theme from '@styles/theme';
import GlobalStyles from '@styles/global';
import { ThemeProvider } from 'styled-components';
import { StylesProvider, ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import { CssBaseline } from '@material-ui/core';
import { HelmetProvider } from 'react-helmet-async';
import { ColorModeContextProvider } from '@context/ColorModeContext';
import 'normalize.css';

const Root = ({ element }) => (
  <HelmetProvider>
    <StylesProvider injectFirst>
      <MuiThemeProvider theme={theme}>
        <ThemeProvider theme={theme}>
          <ColorModeContextProvider>
            <CssBaseline />
            <GlobalStyles />
            {element}
          </ColorModeContextProvider>
        </ThemeProvider>
      </MuiThemeProvider>
    </StylesProvider>
  </HelmetProvider>
);

Root.propTypes = {
  element: PropTypes.node.isRequired,
};

export default Root;
