const colors = {
  blue: '#0a1f3e',
  red: '#8e4944',
  yellow: '#efbe7d',
  orange: '#fdad77',
  black: '#000',
  dark: '#29343d',
  white: '#ffffff',
  bg: '#fdfaf4',
};

export default colors;
