import React, { useReducer } from 'react';
import ColorModeContext from './ColorModeContext';
import ColorModeContextReducer, { initialState } from './ColorModeContextReducer';

const ColorModeContextProvider = ({ children }) => (
  <ColorModeContext.Provider value={useReducer(ColorModeContextReducer, initialState)}>
    {children}
  </ColorModeContext.Provider>
);

export default ColorModeContextProvider;
